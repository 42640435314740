<template>
  <v-container>
    <v-row>
      <v-col md="12" lg="8">
        <CardBox title="Student availablity" minWidth="350" maxWidth="1800">
          <template v-slot:header>
            <v-card v-if="courseFilter" class="caption">
              {{ courseFilterText(courseFilter) }}
              <v-icon @click="resetFilters">mdi-delete</v-icon>
            </v-card>
            <v-card v-else class="caption">
              all courses
            </v-card>
          </template>
          <HeatMap
            :hideRows="['Sunday']"
            :hideCols="['0:00a', '1:00a', '2:00a', '3:00a', '11:00p']"
            :spotSize="spotSize()"
            :rowLabels="defaultDayLabels"
            :colLabels="defaultTimeLabels"
            :values="heatMapValues"
            :gradient="[0.1, 1]"
          />
        </CardBox>
      </v-col>
      <v-col md="12" lg="4">
        <CardBox
          title="Top courses"
          minWidth="200"
          maxWidth="900"
          sm="12"
          lg="8"
        >
          <template v-slot:header>
            <v-fab-transition v-if="true">
              <v-btn
                v-show="true"
                color="pink"
                fab
                dark
                small
                @click="onToggleTopChart"
              >
                <v-icon v-if="!showTopChart">mdi-eye</v-icon>
                <v-icon v-if="showTopChart">mdi-eye-off</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>

          <TopChart
            :values="topCourses"
            @chart-click="onChartClick"
            v-if="showTopChart"
          />
        </CardBox>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" lg="3" xl="2">
        <TermsBox
          :terms="config.terms"
          :selectedTerms="selectedTerms"
          @termsChanged="onTermsChanged"
        />
      </v-col>
      <v-col md="12" lg="3" xl="2">
        <LocationsBox
          :locations="config.locations"
          :selectedLocations="selectedLocations"
          @locationsChanged="onLocationsChanged"
        />
      </v-col>
      <v-col md="12" lg="6" xl="8">
        <CoursesBox
          :courseTableItems="courseTableItems"
          @filterUpdate="onCourseBoxFilterUpdate"
          @download="onDownloadTimesData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped></style>
<script>
import HeatMap from "@/components/HeatMap";
import CardBox from "@/components/CardBox";
import TermsBox from "@/components/TermsBox";
import LocationsBox from "@/components/LocationsBox";
import CoursesBox from "@/components/CoursesBox";
import TopChart from "@/components/TopChart";
import { toCSV, jp } from "@/utils";
import { mapGetters } from "vuex";
import {
  // downloadUserData,
  aggregateTimes,
  aggregateCourses,
} from "@/api/userdata";
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("Times");

export default {
  name: "Times",
  components: {
    CardBox,
    TermsBox,
    LocationsBox,
    CoursesBox,
    HeatMap,
    TopChart,
  },
  computed: {
    ...mapGetters({
      config: "getConfig",
    }),
  },

  data: () => ({
    selectedTerms: [
      // "2021Winter", "2021Spring", "2021Summer", "2021Fall",
      // "2022Winter", "2022Spring", "2022Summer",
      "2022Fall",
      // "2023Winter"
    ],
    selectedLocations: [],
    heatColor: [155, 81, 224],
    heat: 0.005,
    textHigh: "white",
    textLow: "#828282",
    defaultTimeLabels: [],
    defaultDayLabels: [],
    heatMapValues: undefined,
    topCourses: [],
    topChartSize: 10,
    courseFilter: undefined,
    courseTableItems: [],
    courseTableSelection: [],
    showTopChart: false,
  }),

  methods: {
    getAllLocationIds() {
      return _.map(_.get(this.config, "locations"), (x) => _.get(x, "id"));
    },
    spotSize() {
      // $vuetify.breakpoint.smAndDown ? 18 : 36
      if (this.$vuetify.breakpoint.xsOnly) return 14;
      if (this.$vuetify.breakpoint.smOnly) return 20;
      if (this.$vuetify.breakpoint.mdOnly) return 26;
      if (this.$vuetify.breakpoint.lgOnly) return 32;
      return 38;
    },
    initHeatMap: function(rows, cols) {
      let data = [];
      for (let r = 0; r < rows; r++) {
        let row = new Array(cols);
        row.fill(0);
        data.push(row);
      }
      return data;
    },
    getConfigTimeLabels() {
      return this.config.heatMap.defaultTimeLabels;
    },
    getConfigDayLabels() {
      return this.config.heatMap.defaultDayLabels;
    },
    onTermsChanged(event) {
      this.selectedTerms = _.get(event, "selectedTerms");
      logger.debug(`onTermsChanged: ${jp(this.selectedTerms)}`);
      this.updateControls();
    },
    onLocationsChanged(event) {
      this.selectedLocations = _.get(event, "selectedLocations");
      logger.debug(`onLocationsChanged: ${jp(this.selectedLocations)}`);
      this.updateControls();
    },
    onToggleTopChart(){
      this.showTopChart = !this.showTopChart
      this.updateTopCourseChart()
    },
    resetFilters() {
      this.courseFilter = undefined;
      this.courseTableSelection = [];
      // this.updateHeatMap();
      this.updateControls();
    },
    courseFilterText(filter) {
      return (filter || []).length > 3
        ? `[${filter[0]},${filter[1]},${filter[2]},...]`
        : jp(filter);
    },
    updateControls() {
      this.updateHeatMap();
      this.updateTopCourseChart();
    },
    updateHeatMap() {
      aggregateTimes(
        this.config,
        this.selectedTerms,
        this.courseFilter,
        this.selectedLocations
      ).then((values) => {
        logger.debug(`aggregateTimes: ${JSON.stringify(values)}`);
        this.heatMapValues = values;
      });
    },
    onDownloadTimesData(event) {
      const columns = ["course", "count"];
      const exportSelection = _.get(event, "exportSelection");
      this.$emit("download", {
        data: `data:text/plain,${toCSV(columns, exportSelection)}`,
        fileName: `courses-${jp(this.selectedTerms)}-${
          new Date().toISOString().split("T")[0]
        }.csv`,
      });
    },
    // onCourseTableInput(event) {
    //   logger.debug(
    //     `onCourseTableInput, event ${jp(event)}, ${jp(
    //       this.courseTableSelection
    //     )}`
    //   );
    //   this.courseFilter = _.get(event, "courseFilter");
    //   this.updateControls();
    // },
    // onCourseTableClick(event) {
    //   logger.debug(`onCourseTableClick`, event);
    //   this.courseFilter = _.get(event, "courseFilter");
    //   this.updateControls();
    // },
    onCourseBoxFilterUpdate(event){
      logger.debug(`onCourseBoxFilterUpdate, event: ${jp(event)}`)
      this.courseFilter = _.get(event, "courseFilter");
      this.updateControls();
    },
    onChartClick(event) {
      logger.debug("onChartClick", event);
      this.courseFilter = [_.get(event, "label")];
      this.updateHeatMap();
    },
    updateTopCourseChart() {
      aggregateCourses(this.selectedTerms, this.selectedLocations, this.courseFilter).then(
        (data) => {
          this.courseTableItems = data;
          this.topCourses = _.map(
            _.slice(_.sortBy(data, [(r) => -r.count]), 0, this.topChartSize),
            (v) => {
              return { label: v.course, value: v.count };
            }
          );
          logger.debug("updateTopCourseChart, complete", this.topCourses);
        }
      );
    },
  },

  created() {
    logger.debug("created");
    this.selectedLocations = this.getAllLocationIds();
    this.defaultTimeLabels = this.config.heatMap.defaultTimeLabels;
    this.defaultDayLabels = this.config.heatMap.defaultDayLabels;
    this.heatMapValues = this.initHeatMap(
      this.defaultDayLabels.length,
      this.defaultTimeLabels.length
    );
    this.updateControls();
  },
};
</script>
