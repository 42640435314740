<template>
  <v-container>
    <v-row>
      <v-col>
        <CardBox
          title="Modalities"
          minWidth="150"
          maxWidth="350"
          sm="12"
          lg="8"
        >
          <template v-slot:header>
            <v-card v-if="courseFilter" class="caption">
              {{ courseFilterText(courseFilter) }}
              <v-icon @click="resetFilters">mdi-delete</v-icon>
            </v-card>
            <v-card v-else class="caption">
              all courses
            </v-card>
          </template>
          <PieChart :values="chartModalities" @chart-click="onChartClick" />
        </CardBox>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" lg="3" xl="2">
        <TermsBox
          :terms="config.terms"
          :selectedTerms="selectedTerms"
          @termsChanged="onTermsChanged"
        />
        <LocationsBox
          :locations="config.locations"
          :selectedLocations="selectedLocations"
          @locationsChanged="onLocationsChanged"
        />
      </v-col>
      <v-col md="12" lg="9" xl="10">
        <CardBox title="All modalities" minWidth="350" maxWidth="1800">
          <template v-slot:header>
            <v-icon @click="onDownloadModalitiesData">mdi-file-download</v-icon>
          </template>
          <v-card>
            <v-card-title>
              <v-text-field
                v-model="studentTableSearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="studentTableHeaders"
              :items="studentTableItems"
              :search="studentTableSearch"
              :single-select="false"
              item-key="id"
              v-model="studentTableSelection"
            >
            </v-data-table>
          </v-card>
        </CardBox>
      </v-col>
      <v-col>
        <CoursesBox
          :courseTableItems="courseTableItems"
          :courseTableSelection="courseTableSelection"
          @tableInput="onCourseTableInput"
          @tableClick="onCourseTableClick"
          @download="onDownloadTimesData"
          :key="courseTableKey"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped></style>
<script>
import CardBox from "@/components/CardBox";
import TermsBox from "@/components/TermsBox";
import LocationsBox from "@/components/LocationsBox";
import CoursesBox from "@/components/CoursesBox";
import PieChart from "@/components/PieChart";
import { mapGetters } from "vuex";
import {
  queryUserModalities,
  aggregateModalitites,
  aggregateCourses,
} from "@/api/userdata";
import { translateTerm } from "@/api/config";
import { toCSV, jp } from "@/utils";
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("Modalities");
//:courseTableSelection="courseTableSelection"
export default {
  name: "Times",
  components: {
    CardBox,
    TermsBox,
    LocationsBox,
    CoursesBox,
    PieChart,
  },
  computed: {
    ...mapGetters({
      config: "getConfig",
    }),
    studentTableHeaders() {
      return [
        { text: "student", value: "user", filterable: true },
        {
          text: "term",
          value: "term",
          filter: (v) => {
            let translated = _.map(this.selectedTerms, (v) =>
              translateTerm(v, this.config.terms)
            );
            return _.indexOf(translated, v) >= 0;
          },
        },
        { text: "course", value: "course", filterable: true },
        { text: "modalities", value: "modalities" },
      ];
    },
  },

  data: () => ({
    selectedTerms: [
      // "2021Winter", "2021Spring", "2021Summer", "2021Fall",
      // "2022Winter", "2022Spring", "2022Summer",
      "2022Fall",
      // "2023Winter"
    ],
    selectedLocations: [],
    studentFilter: undefined,
    studentTableItems: [],
    studentTableSearch: "",
    studentTableSelection: [],
    chartModalities: [],
    courseTableItems: [],
    courseTableSelection: [],
    courseTableKey: `${Date.now()}`,
    courseFilter: undefined,
  }),

  methods: {
    updateCourseTableKey() {
      this.courseTableKey = `${Date.now()}`;
    },
    getAllLocationIds() {
      return _.map(_.get(this.config, "locations"), (x) => _.get(x, "id"));
    },
    onDownloadModalitiesData() {
      const columns = [
        "user",
        "term",
        "course",
        "Online",
        "Hybrid",
        "In person",
      ];
      this.$emit("download", {
        data: `data:text/plain,${toCSV(columns, this.studentTableItems, (v) => {
          return {
            user: _.get(v, "user"),
            term: _.get(v, "term"),
            course: _.get(v, "course"),
            Online: _.indexOf(_.get(v, "modalities"), "Online") >= 0,
            Hybrid: _.indexOf(_.get(v, "modalities"), "Hybrid") >= 0,
            "In person": _.indexOf(_.get(v, "modalities"), "In person") >= 0,
          };
        })}`,
        fileName: `modalities-${new Date().toISOString().split("T")[0]}.csv`,
      });
    },
    onChartClick() {
      logger.debug("onChartClick", event);
    },
    updateChart(data) {
      const modalities = ["Online", "Hybrid", "In person"];
      this.chartModalities = _.map(modalities, (modality) => {
        return { label: modality, value: _.get(data, modality) || 0 };
      });
      logger.debug("complete", jp(this.chartModalities));
    },
    onTermsChanged(event) {
      this.selectedTerms = _.get(event, "selectedTerms");
      logger.debug(`onTermsChanged: ${jp(this.selectedTerms)}`);
      this.updateControls();
    },
    onLocationsChanged(event) {
      this.selectedLocations = _.get(event, "selectedLocations");
      logger.debug(`onLocationsChanged: ${jp(this.selectedLocations)}`);
      this.updateControls();
    },
    onDownloadTimesData(event) {
      const columns = ["course", "count"];
      const exportSelection = _.get(event, "exportSelection");
      this.$emit("download", {
        data: `data:text/plain,${toCSV(columns, exportSelection)}`,
        fileName: `courses-${jp(this.selectedTerms)}-${
          new Date().toISOString().split("T")[0]
        }.csv`,
      });
    },
    onCourseTableInput(event) {
      logger.debug(
        `onCourseTableInput, event ${jp(event)}, ${jp(
          this.courseTableSelection
        )}`
      );
      this.courseFilter = _.get(event, "courseFilter");
      this.courseTableSelection = _.get(event, "courseTableSelection");
      this.updateControls();
    },
    onCourseTableClick(event) {
      logger.debug(`onCourseTableClick`, event);
      this.courseFilter = _.get(event, "courseFilter");
      this.courseTableSelection = _.get(event, "courseTableSelection");
      this.updateControls();
    },
    updateControls() {
      aggregateModalitites(
        this.selectedTerms,
        this.courseFilter,
        this.selectedLocations
      ).then((modalities) => {
        logger.debug(`modalities: ${jp(modalities)}`);
        this.updateChart(modalities);
        this.updateCourseTableKey();
      });
      aggregateCourses(this.selectedTerms, this.selectedLocations).then(
        (data) => {
          this.courseTableItems = data;
          this.updateCourseTableKey();
        }
      );
    },
    updateUserModalities() {
      queryUserModalities().then((data) => {
        this.studentTableItems = _.map(data, (v) => {
          //   logger.debug(`queryUserModalities, ${jp(v)}`);
          const term = translateTerm(_.get(v, "term"), this.config.terms);
          const user = _.get(v, "user");
          const course = _.get(v, "course");
          return {
            id: `${user}-${course}-${term}`,
            modalities: _.get(v, "modalities"),
            course: course,
            term: term,
            user: user,
          };
        });
      });
      this.updateControls();
    },
    courseFilterText(filter) {
      return (filter || []).length > 3
        ? `[${filter[0]},${filter[1]},${filter[2]},...]`
        : jp(filter);
    },
    resetFilters() {
      this.courseFilter = undefined;
      this.courseTableSelection = [];
      this.updateUserModalities();
    },
  },

  created() {
    logger.debug("created");
    this.selectedLocations = this.getAllLocationIds();
    this.updateUserModalities();
  },
};
</script>
