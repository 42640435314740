<template>
  <CardBox title="All courses" minWidth="350" maxWidth="600" sm="12" lg="4">
    <template v-slot:header>
      <v-fab-transition v-if="showFunnel">
        <v-btn v-show="true" color="pink" fab dark small @click="onFilterUpdate">
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-icon @click="onDownloadTimesData">mdi-file-download</v-icon>
    </template>

    <v-card>
      <v-card-title>
        <v-text-field
          v-model="courseTableSearch"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="courseTableHeaders"
        :items="courseTableItems"
        :search="courseTableSearch"
        :single-select="false"
        item-key="course"
        v-model="courseTableSelectionState"
        show-select
        @input="onCourseTableInput"
        v-on:click:row="onCourseTableClick"
        @current-items="onCurrentItems"
        @item-selected="onItemSelected"
      >
      </v-data-table>
    </v-card>
  </CardBox>
</template>

<script>
import CardBox from "@/components/CardBox";
import { jp } from "@/utils";
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("CoursesBox");

export default {
  name: "CoursesBox",
  components: { CardBox },
  props: {
    locations: { type: Array, default: () => [] },
    selectedLocations: { type: Array, default: () => [] },
    courseTableItems: { type: Array, default: () => [] },
    courseTableSelection: { type: Array, default: () => [] },
  },
  data: () => ({
    locationsState: {},
    courseTableSearch: "",
    courseTableHeaders: [
      { text: "id", value: "course", filterable: true },
      { text: "count", value: "count" },
    ],
    courseTableSelectionState: [],
    courseFilter: undefined,
    showFunnel: false,
  }),
  methods: {
    onItemSelected(event){
      logger.debug(`onItemSelected, event: ${jp(event)}`);
      this.showFunnel = true;
    },
    onCurrentItems(event) {
      logger.debug(`onCurrentItems, event: ${jp(event)}`);
      // this.showFunnel = true;
    },
    onDownloadTimesData() {
      const exportSelection =
        this.courseTableSelectionState &&
        this.courseTableSelectionState.length > 0
          ? this.courseTableSelectionState
          : this.courseTableItems;
      this.$emit("download", { exportSelection });
    },
    onCourseTableInput(event) {
      logger.debug(
        `onCourseTableInput, event ${jp(event)}, ${jp(
          this.courseTableSelectionState
        )}`
      );
      this.courseFilter = event.length > 0 ? _.map(event, "course") : undefined;
      this.$emit("tableInput", {
        courseFilter: _.cloneDeep(this.courseFilter),
        courseTableSelection: _.cloneDeep(this.courseTableSelectionState),
      });
    },
    onCourseTableClick(event) {
      logger.debug(`onCourseTableClick`, event);
      this.courseFilter =
        event.length > 0 ? [_.get(event, "course")] : undefined;
      this.$emit("tableInput", {
        courseFilter: _.cloneDeep(this.courseFilter),
        courseTableSelection: _.cloneDeep(this.courseTableSelectionState),
      });
    },
    onFilterUpdate(){
      this.$emit("filterUpdate", {
        courseFilter: _.cloneDeep(this.courseFilter),
        courseTableSelection: _.cloneDeep(this.courseTableSelectionState),
      });
      this.showFunnel = false
    },
    setState() {
      // let state = {};
      // _.each(this.selectedLocations, (v) => {
      //   state[v] = true;
      // });
      // this.locationsState = state;
    },
  },
  mounted() {
    this.setState();
  },
  created() {
    logger.debug(`created, locationsState: ${jp(this.locationsState)}`);
    this.courseTableSelectionState = this.courseTableSelection;
  },
};
</script>
