import { apiRoot } from '@/api/config'
import { getAuthKey, jp, toJSON } from '@/utils';
import db from '@/api/db'
import axios from 'axios'
import _, { max } from 'lodash'
import logdown from 'logdown'
const logger = logdown("userdata");

// hack for the locations
// const _locations = [
//     "Dayton Campus",
//     "Centerville CENT",
//     "Mason CVCC",
//     "Englewood ELC",
//     "Huber Heights HHLC"
// ];


function parseTimes(data) {
    const courses = _.get(data, 'courses')
    if (courses) {
        return _.map(_.keys(courses), term => {
            const termCourses = _.map(_.keys(_.get(courses, term)), slot => {
                return _.get(courses, [term, slot, 'id'])
            })
            const times = _.get(data, 'times')
            return { courses: termCourses, term: term, times: _.get(times, term) || {} }
        })
    } else {
        return []
    }
}

function parseLocations(data) {
    const locations = _.get(data, 'locations') || {};
    logger.info('parseLocations, locations:', toJSON(locations));
    return locations
}

function parseModalities(data) {
    const courses = _.get(data, 'courses')
    let out = []
    if (data) {
        _.map(_.keys(courses), term => {
            _.map(_.keys(_.get(courses, term)), slot => {
                out.push({
                    term: term,
                    course: _.get(courses, [term, slot, 'id']),
                    modalities: _.get(courses, [term, slot, 'modalities']),
                    unselectedModalities: _.get(courses, [term, slot, 'unselectedModalities'])
                })
            })
        })
        return out
    } else {
        return []
    }
}

function parseConfirmations(data) {
    const courses = _.get(data, 'courses')
    let out = []
    if (data) {
        _.map(_.keys(courses), term => {
            const confirmation = _.get(data, ['confirmations', term])
            const slots = _.get(courses, [term])
            const courseIds = _.map(_.keys(slots), slot => _.get(data, ['courses', term, slot, 'id']))
            out.push({
                term: term,
                courses: courseIds,
                confirmation: confirmation
            })
        })
        return out
    } else {
        return []
    }
}

function formatTimes(userTimes, timeSlots) {
    const userSelected = _.get(userTimes, 'selected')
    const userUnselected = _.get(userTimes, 'unselected')
    // logger.debug(`selected: ${JSON.stringify(userSelected)}, unselected: ${JSON.stringify(userUnselected)}`)
    const days = _.get(timeSlots, 'days')
    const times = _.get(timeSlots, 'times')
    const unselectTimes = _.concat(_.get(timeSlots, 'unselectTimes'), _.get(timeSlots, 'excludeTimes'))
    const unselectDays = _.concat(_.get(timeSlots, 'unselectDays'), _.get(timeSlots, 'excludeDays'))
    // logger.debug(`unselectTimes: ${JSON.stringify(unselectTimes)}`)
    // logger.debug(`unselectDays: ${JSON.stringify(unselectDays)}`)
    return _.map(days, day => {
        return _.map(times, time => {
            const slot = `${day}-${time}`
            if (_.indexOf(userSelected, slot) >= 0) {
                return 1
            }
            if (_.indexOf(userUnselected, slot) >= 0) {
                return 0
            }
            return _.indexOf(unselectTimes, time) >= 0 ? 0 : 1 && _.indexOf(unselectDays, day) >= 0 ? 0 : 1
        })
    })
}

async function updateTimes(user, userData, timeSlots, _locations) {
    let count = 0
    const times = parseTimes(userData)
    const locations = parseLocations(userData)
    for (let i = 0; i < times.length; i++) {
        const term = _.get(times[i], 'term')
        const unselectedLocations = _.get(locations, [term, 'unselectedLocations']) || [];
        const selectedLocations = _.difference(_locations, unselectedLocations);
        const record = {
            user: user,
            term: term,
            courses: _.get(times[i], 'courses'),
            times: formatTimes(_.get(times[i], 'times'), timeSlots),
            locations: _.size(selectedLocations) == 0 ? _locations : selectedLocations
        }
        // let id = 
        try {
            await db.times.put(record)
        } catch (e) {
            logger.error(`updateTimes: ${e}`)
        }
        // logger.debug(`times id:${id}`);
        count++
    }
    return count
}

async function updateModalitites(user, userData, _locations) {
    let count = 0
    const modalities = parseModalities(userData)
    const locations = parseLocations(userData)
    for (let i = 0; i < modalities.length; i++) {
        const term = _.get(modalities[i], 'term')
        const unselectedLocations = _.get(locations, [term, 'unselectedLocations']) || [];
        const selectedLocations = _.difference(_locations, unselectedLocations);
        const record = {
            user: user,
            term: _.get(modalities[i], 'term'),
            course: _.get(modalities[i], 'course'),
            modalities: _.difference(_.get(modalities[i], 'modalities'), _.get(modalities[i], 'unselectedModalities')),
            locations: _.size(selectedLocations) == 0 ? _locations : selectedLocations
        }
        if (_.get(record, 'course')) {
            try {
                await db.modalities.put(record)
                // logger.info(`updateModalitites: ${toJSON(record)}`)
            } catch (e) {
                logger.error(`updateModalitites: ${e}, ${toJSON(record)}`)
            }
        }
        count++
    }
    return count
}

async function updateConfirmations(user, userData) {
    let count = 0
    const confirmations = parseConfirmations(userData)
    logger.debug(`confirmations: ${jp(confirmations)}`)
    for (let i = 0; i < confirmations.length; i++) {
        const record = {
            user: user,
            confirmation: _.get(confirmations[i], 'confirmation'),
            term: _.get(confirmations[i], 'term'),
            courses: _.get(confirmations[i], 'courses'),
        }
        try {
            await db.confirmations.put(record)
        } catch (e) {
            logger.error(`updateConfirmations: ${e}`)
        }
        count++
    }
    return count
}

// function validateRecord(user, data) {
//     const times = _.get(data, 'times') || {}
//     const courses = _.get(data, 'courses') || {}
//     if (_.keys(courses).length > 0 && _.keys(times).length == 0) {
//         logger.warn(`validateRecord: ${user}, course terms: ${_.keys(courses).length}, times terms: ${_.keys(times).length}`)
//     }
// }


function downloadData(kind, filter, progress, options) {
    logger.debug('downloadData')
    return new Promise((resolve, reject) => {
        const url = `${apiRoot()}/rk?k=${kind}&f=${filter}&localKey=${getAuthKey()}`
        axios
            .get(url, { responseType: "text", })
            .then(async ({ data }) => {
                // const term = _.get(queryOptions, ['term']);
                let sStart = 0;
                let sEnd = 0;
                let recordCount = 0
                while (sEnd < data.length) {
                    if (data[sEnd] == "\n" || data[sEnd] == "\r") {
                        const str = data.slice(sStart, sEnd);
                        if (str != '' && str != '\n' && str != '\r') {
                            let record;
                            try {
                                record = JSON.parse(str);
                                const recordData = _.get(record, ["data"]);
                                const recordKey = _.get(record, ["key"]);
                                if (options && options.data) {
                                    options.data(recordKey, recordData)
                                }
                                recordCount++
                                if (progress) {
                                    progress({ count: recordCount })
                                }
                            } catch (e) {
                                logger.error(`str:${typeof str == 'string' ? str.length : str}, ${e}`)
                            }
                        }
                        sStart = sEnd;
                    }
                    sEnd++;
                }
                resolve({
                    recordCount: recordCount,
                })
            })
            .catch((e) => { reject(e) })
    })
}

function downloadUserData({ timeSlots }, reportProgress, { locations }) {
    logger.debug('downloadUserData, locations:', jp(locations))
    return new Promise((resolve, reject) => {
        const url = `${apiRoot()}/r?localKey=${getAuthKey()}`
        axios
            .get(url, { responseType: "text", })
            .then(async ({ data }) => {
                // const term = _.get(queryOptions, ['term']);
                let sStart = 0;
                let sEnd = 0;
                let recordCount = 0
                let confirmationsCount = 0
                let modalitiesCount = 0
                let timesCount = 0
                // TODO: process the last record
                while (sEnd < data.length) {
                    if (data[sEnd] == "\n" || data[sEnd] == "\r") {
                        const str = data.slice(sStart, sEnd);
                        if (str != '' && str != '\n' && str != '\r') {
                            let userRecord;
                            try {
                                userRecord = JSON.parse(str);
                                const user = userRecord.user;
                                if (_.startsWith(user, '0') || _.startsWith(user, '@')) {
                                    const userData = _.get(userRecord, ["data", "userData"]);
                                    confirmationsCount += await updateConfirmations(user, userData)
                                    timesCount += await updateTimes(user, userData, timeSlots, locations)
                                    modalitiesCount += await updateModalitites(user, userData, locations)
                                    recordCount++
                                    if (reportProgress && recordCount % 10 == 0) {
                                        reportProgress(recordCount)
                                    }
                                }
                                else {
                                    logger.warn(`filtered out: ${user}`)
                                }
                            } catch (e) {
                                logger.error(`str:${typeof str == 'string' ? str.length : str}, ${e}`)
                            }
                        }
                        sStart = sEnd;
                    }
                    sEnd++;
                }
                resolve({
                    recordCount: recordCount,
                    modalitiesCount: modalitiesCount,
                    timesCount: timesCount,
                    confirmationsCount: confirmationsCount,
                })
            })
            .catch((e) => { reject(e) })
    })
}

function initValues(rows, cols) {
    let data = [];
    for (let r = 0; r < rows; r++) {
        let row = new Array(cols);
        row.fill(0);
        data.push(row);
    }
    return data;
}

function sumVectors(v1, v2) {
    return _.map(v1, (v, i) => v + v2[i])
}

function addMatricies(m1, m2) {
    return _.map(m1, (v, i) => sumVectors(v, m2[i]))
}

function mmax(m) {
    return max(_.map(m, v => max(v)))
}

function normalize(m) {
    const maxVal = mmax(m)
    return maxVal == 0 ? m :
        _.map(m, v => _.map(v, c => c / maxVal))
}

function aggregateModalitites(terms, courses, locations) {
    logger.debug('aggregateModalitites, terms:', terms)
    const locationFilter = _.isArray(locations) ? locations : [locations]
    _.remove(locationFilter, x => !x)
    const courseFilter = _.isArray(courses) ? courses : [courses]
    _.remove(courseFilter, x => !x)
    return new Promise(resolve => {
        let out = {}
        db.modalities
            .where('term').anyOf(terms)
            .toArray().then(array => {
                _.forEach(array, record => {
                    //record: {"user":"000678235","term":"2022Spring","course":"ACCT601","modalities":["In person","Hybrid","Online"]}
                    logger.debug(`aggregateModalitites, record: ${toJSON(record)}`)
                    const modalities = _.get(record, 'modalities') || []
                    const locations = _.get(record, 'locations') || []
                    const course = _.get(record, 'course')
                    _.each(modalities, modality => {
                        if (0 == _.size(courseFilter) || _.indexOf(courseFilter, course) >= 0) {
                            if (0 == _.size(locationFilter) ||
                                _.some(locations, x => _.indexOf(locationFilter, x) >= 0)) {
                                if (_.get(out, [modality])) {
                                    out[modality] = out[modality] + 1
                                } else {
                                    out[modality] = 1
                                }
                            }
                        }
                    })
                })
                resolve(out)
            })
    })
}

function aggregateCourses(terms, locations, courses) {
    logger.debug('aggregateCourses, terms:', terms)
    const locationFilter = _.isArray(locations) ? locations : [locations]
    _.remove(locationFilter, x => !x)
    const courseFilter = _.isArray(courses) ? courses : [courses]
    _.remove(courseFilter, x => !x)
    return new Promise((resolve) => {
        db.modalities.where('term').anyOf(terms).toArray().then(array => {
            let data = {}
            _.forEach(array, record => {
                const course = _.get(record, 'course')
                const locations = _.get(record, 'locations')
                if (0 == _.size(courseFilter) || _.indexOf(courseFilter, course) >= 0) {
                    if (0 == _.size(locationFilter) ||
                        _.some(locations, x => _.indexOf(locationFilter, x) >= 0)) {
                        if (!data[course]) {
                            data[course] = 1
                        } else {
                            data[course]++
                        }
                    }
                }
            })
            resolve(_.map(data, (v, k) => { return { course: k, count: v } }))
        })
    })
}

function aggregateTimes({ timeSlots }, terms, courses, locations) {
    const courseFilter = _.isArray(courses) ? courses : [courses]
    _.remove(courseFilter, x => !x)
    const locationFilter = _.isArray(locations) ? locations : [locations]
    _.remove(locationFilter, x => !x)
    const days = _.get(timeSlots, 'days')
    const times = _.get(timeSlots, 'times')
    return new Promise((resolve) => {
        db.times.where('term').anyOf(terms).filter((timesRecord) => {
            return (
                (0 == _.size(courseFilter) ||
                    _.find(timesRecord.courses, course => {
                        return _.indexOf(courseFilter, course) >= 0
                    })) &&
                (0 == _.size(locationFilter) ||
                    _.find(timesRecord.locations, location => {
                        return _.indexOf(locationFilter, location) >= 0
                    }))
            )
        }).toArray().then(array => {
            let values = initValues(days.length, times.length)
            _.forEach(array, record => {
                values = addMatricies(values, _.get(record, 'times'))
            })
            resolve(normalize(values))
        })
    })
}

function queryUserConfirmations() {
    return new Promise((resolve) => {
        db.confirmations.toArray().then(array => {
            resolve(array)
        })
    })
}

function queryUserModalities() {
    return new Promise((resolve) => {
        db.modalities.toArray().then(array => {
            resolve(array)
        })
    })
}

function downloadPromoData() {
    return new Promise(resolve => {
        let array = []
        downloadData('UserPromoData', undefined, ({ count }) => { logger.debug(`${count}`) }, {
            data: (key, data) => {
                logger.debug(`${key}, ${jp(data)}`)
                if (/^[0-9]/.test(key)) {
                    array.push({
                        user: key, code: _.get(data, 'code'),
                        refs: _.get(data, 'refs'), count: _.get(data, 'count')
                    })

                }
            }
        }).then(() => { resolve(array) })
    })
}

function downloadFeedbackData() {
    return new Promise(resolve => {
        let array = []
        downloadData('Feedback', undefined, ({ count }) => { logger.debug(`${count}`) }, {
            data: (key, data) => {
                logger.debug(`${key}, ${jp(data)}`)
                const user = _.get(data, 'user')
                if (/^[0-9]/.test(user)) {
                    array.push({
                        user: user, time: _.get(data, 'time'),
                        text: _.get(data, 'text'), rating: _.get(data, 'rating'),
                        mode: _.get(data, 'mode')
                    })

                }
            }
        }).then(() => { resolve(array) })
    })
}

export {
    downloadUserData,
    aggregateTimes,
    aggregateCourses,
    aggregateModalitites,
    queryUserConfirmations,
    queryUserModalities,
    downloadPromoData,
    downloadFeedbackData,
}