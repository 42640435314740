<template>
  <CardBox title="Locations" maxWidth="250">
    <template v-slot:header> </template>
    <v-list two-line subheader>
      <v-list-item
        v-for="location in locations"
        :key="location.id"
        @click="toggleLocation(location.id)"
      >
        <v-list-item-action>
          <v-checkbox :value="locationsState[location.id]"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ location.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </CardBox>
</template>

<script>
import CardBox from "@/components/CardBox";
import { jp } from "@/utils";
import _ from "lodash";
import logdown from "logdown";
const logger = logdown("LocationsBox");

export default {
  name: "LocationsBox",
  components: { CardBox },
  props: {
    locations: { type: Array, default: () => [] },
    selectedLocations: { type: Array, default: () => [] },
  },
  data: () => ({
    locationsState: {},
  }),
  methods: {
    toggleLocation(location) {
      if (_.get(this.locationsState, location)) {
        _.unset(this.locationsState, location);
      } else {
        _.set(this.locationsState, location, true);
      }
      logger.debug(`toggleLocation, ${jp(this.locationsState)}`);
      this.$forceUpdate();
      this.$emit("locationsChanged", { selectedLocations: _.keys(this.locationsState) });
    },
    setState() {
      let state = {};
      _.each(this.selectedLocations, (v) => {
        state[v] = true;
      });
      this.locationsState = state;
    },
  },
  mounted() {
    this.setState()
  },
  created() {
    logger.debug(`created, locationsState: ${jp(this.locationsState)}`);
  },
};
</script>
